import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { ProjectCard } from './ProjectCard';
import ContentOverlay from './ContentOverlay';

const CARD_HEIGHT = '320px';
const CARD_WIDTH = '10PX';

const MEDIA_QUERY_SMALL = '@media (max-width: 800px)';

class Project extends React.Component {
  state = {
    hover: false,
  };

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    const { title, thumbnail, subtitle, tags, gridCol, gridRow, path } = this.props;

    return (
      <ProjectCard
        projektBackground={thumbnail.file.url}
        cardWith={CARD_WIDTH}
        mediaQuerySmall={MEDIA_QUERY_SMALL}
        onMouseEnter={this.toggleHover.bind(this)}
        onMouseLeave={this.toggleHover.bind(this)}
        projectGridCol={gridCol}
        projectGridRow={gridRow}
      >
        <Flex
          style={{
            height: `${CARD_HEIGHT}`,
          }}
        >
          <ContentOverlay
            visible={this.state.hover}
            title={title}
            subtitle={subtitle}
            tags={tags}
            cardHeight={CARD_HEIGHT}
            path={path}
          />
        </Flex>
      </ProjectCard>
    );
  }
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  tags: PropTypes.array.isRequired,
  gridRow: PropTypes.string.isRequired,
  gridCol: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default Project;
