import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Text, Flex, Image } from 'rebass';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import { ServiceCardContainer, ServiceCard } from '../components/ServiceCard';
import markdownRenderer from '../components/MarkdownRenderer';

const MEDIA_QUERY_SMALL = '@media (max-width: 400px)';

const Title = styled(Text)`
  font-size: 42px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1rem;
`;

const TextContainer = styled.div`
  padding: 10px;
  margin: 1rem;
  margin-top: 0;
`;

const ImageContainer = styled.div`
  margin: auto;
  margin-bottom: 0;

  ${MEDIA_QUERY_SMALL} {
    margin-top: 2rem;
  }
`;

const ServiceImage = styled(Image)`
  
  padding: 40px;
  padding-bottom: 0px;
  margin-top: 0px;

  ${MEDIA_QUERY_SMALL} {
    padding: 15px;
  }
`;

const Service = ({ title, text, emoji }) => (
  <ServiceCard mx={[ 0, 0, 15]}>
    <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      <ImageContainer>
        <ServiceImage src={emoji.file.url} width={[ "140px", "150px", "200px"]} alt="Emoji" />
        <span>
          <Title my={3} pb={2}>
            {title}
          </Title>
        </span>
      </ImageContainer>
      <TextContainer style={{ zIndex: "2" }}>
        <span>
          <ReactMarkdown source={text.text} renderers={markdownRenderer} />
        </span>
      </TextContainer>
    </Flex>
  </ServiceCard>
);

Service.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  emoji: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
};

const Services = () => (
  <Section.Container id="service">
    <StaticQuery
      query={graphql`
        query ContentfulServices {
          __typename
          allContentfulService {
            edges {
              node {
                id
                title
                text {
                  text
                }
                emoji {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allContentfulService }) => (
        <ServiceCardContainer minWidth="280px">
          {allContentfulService.edges.map((s, i) => (
            <Fade bottom delay={i * 200} key={s.node.id}>
              <Service
                title={s.node.title}
                text={s.node.text}
                emoji={s.node.emoji}
              />
            </Fade>
          ))}
        </ServiceCardContainer>
      )}
    />
  </Section.Container>
);

export default Services;
