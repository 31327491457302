import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Text, Box, Flex } from 'rebass';
import styled from 'styled-components';
import MyButton from './MyButton';

const Title = styled(Text)`
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
`;

const BoxContainer = styled(Box)`
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  height: calc(${props => props.containerHeight} * 1);
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
`;

const TextContainer = styled.div`
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Tag = styled(Text)`
  font-size: 0px;

  @media only screen and (min-width: 1000px) {
    font-size: 12px;
    letter-spacing: 0.03rem;
    padding: 5px;
    padding-top: 3px;
  }
`;

const Subtitle = styled(Text)`
  font-size: 18px;
  letter-spacing: 0.05rem;
  line-height: 1.6rem;

  &:first-child {
    margin-top: 0em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 400px) {
    transform: translate(0, -20px);
  }
`;

const ContentOverlay = ({
  visible,
  title,
  subtitle,
  tags,
  path,
}) => {
  if (visible) {
    return (
      <BoxContainer>
        <TextContainer width="100%">
          <span>
            <Title my={2} pb={1}>
              {title}
            </Title>
          </span>
          <Subtitle style={{ overflow: 'auto' }}>{subtitle}</Subtitle>
          <Flex
            flexWrap="wrap"
            m={3}
            justifyContent="center"
            alignItems="center"
          >
            {tags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Flex>
        </TextContainer>
        <ButtonContainer>
          <Link to={path}>
            <MyButton label="see details 🕵️‍♂️" />
          </Link>
        </ButtonContainer>
      </BoxContainer>
    );
  }
  return null;
};

ContentOverlay.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  tags: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default ContentOverlay;
