import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MyButton from '../components/MyButton';
import Section from '../components/Section';
import MouseIcon from '../components/MouseIcon';
import TitleImg from '../img/landing_ThatsMe.jpg';
import SphereImg from '../img/landing_spheres.png';
import TitleComponent from '../components/TitleComponent';

const TitleContainer = styled.div`
  width: 35%;

  @media only screen and (max-width: 400px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  float: right;

  @media only screen and (max-width: 400px) {
    transform: translate(0, 200px);
    margin-top: 50px;
  }
`;

const SphereContainer = styled.div`
  margin-top: 550px;
  width: 97%;
  z-index: 1;
  position: absolute;

  @media only screen and (max-width: 400px) {
    margintop: 0;
    width: 0%;
  }

  @-moz-document url-prefix() {
      margin-top: 1100px;
  }
`;

const LandingPage = () => (
  <Section.Container id="home">
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          contentfulAbout {
            name
            roles
          }
          site {
            siteMetadata {
              deterministicBehaviour
            }
          }
        }
      `}
      render={({ contentfulAbout }) => {
        const { name } = contentfulAbout;

        return (
          <Fragment>
            <TitleContainer>
              <TitleComponent name={name} />

              <ButtonContainer>
                <a href="mailto:office@johnthehuman.com?subject=Hi%20Johnny">
                  <MyButton label="say hi ✌️" />
                </a>
              </ButtonContainer>
            </TitleContainer>

            <Image
              src={TitleImg}
              width="97%"
              alt="That is me"
              style={{
                zIndex: -1,
                position: 'absolute',
                marginTop: 50,
              }}
            />

            <SphereContainer>
              <Image src={SphereImg} width="100%" alt="sphere" />
            </SphereContainer>

            <SectionLink section="service">
              {({ onClick }) => <MouseIcon onClick={onClick} />}
            </SectionLink>
          </Fragment>
        );
      }}
    />
  </Section.Container>
);

export default LandingPage;
