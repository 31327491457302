import styled from 'styled-components';
import { Card as CardRebass } from 'rebass';

export const ServiceCardContainer = styled.div`
  z-index: 0;
  display: grid;
  grid-gap: 30px;
  margin: 6rem;
  margin-bottom: 1rem;

  grid-template-columns: repeat(
    auto-fill,
    minmax(${props => props.minWidth}, 1fr)
  );
  justify-items: center;
  
  @media only screen and (max-width: 600px) {
    margin: 4rem;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  @media only screen and (max-width: 400px) {
    margin: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }


`;

export const ServiceCard = styled(CardRebass).attrs({
  bg: '#F6F6F6',
  boxShadow: 0,
  borderRadius: 10,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#FAFCFF',
})`
  position: relative;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 1), 10px 10px 20px rgba(102, 119, 170, 0.1);
  transition: all 0.25s;
  top: 0;
  height: 100%;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

export default ServiceCard;
