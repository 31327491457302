import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import markdownRenderer from '../components/MarkdownRenderer';

const ProfilePicture = styled(Image)`
  transition: all 0.25s ease-out;
`;

const Title = styled(Text)`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.05rem;
`;

const About = () => (
  <Section.Container id="about">
    <Section.Header name="About me" />
    <StaticQuery
      query={graphql`
        query ContentfulAboutMe {
          __typename
          contentfulAboutMeExperienceTextNode {
            id
            experience
          }
          contentfulAboutMeGeneralInformationTextNode {
            id
            generalInformation
          }
          contentfulAboutMePhilosophyTextNode {
            id
            philosophy
          }
          contentfulAboutMe {
            profilePicture {
              file {
                url
              }
              sizes(sizes: "1400") {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const {
          generalInformation,
        } = data.contentfulAboutMeGeneralInformationTextNode;
        const { philosophy } = data.contentfulAboutMePhilosophyTextNode;
        const { experience } = data.contentfulAboutMeExperienceTextNode;

        const { profilePicture } = data.contentfulAboutMe;

        return (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
            <Box width={[1, 1, 3 / 8]} px={[1, 2, 4]}>
              <Fade bottom>
                <ReactMarkdown
                  source={generalInformation}
                  renderers={markdownRenderer}
                />
              </Fade>
            </Box>

            <Box
              width={[1, 1, 5 / 8]}
              style={{
                maxWidth: '550px',
                margin: 'auto',
                marginTop: -80,
                marginBottom: -100,
                zIndex: -1,
              }}
            >
              <Fade right>
                <ProfilePicture
                  src={profilePicture.sizes.src}
                  mt={[4, 4, 0]}
                  ml={[0, 0, 1]}
                />
              </Fade>
            </Box>

            <Box width={[1, 1, 3 / 8]} px={[1, 2, 4]}>
              <Fade bottom>
                <Title my={3} pb={2}>
                  Philosophy
                </Title>

                <ReactMarkdown
                  source={philosophy}
                  renderers={markdownRenderer}
                />
              </Fade>
            </Box>
            <Box width={[1, 1, 5 / 8]} px={[1, 2, 4]}>
              <Fade bottom>
                <Title my={3} pb={2}>
                  Experience
                </Title>

                <ReactMarkdown
                  source={experience}
                  renderers={markdownRenderer}
                />
              </Fade>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

export default About;
