import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import { ProjectCardContainer } from '../components/ProjectCard';
import Project from '../components/Project';

const Projects = () => (
  <Section.Container id="Projects">
    <Section.Header name="selected projects" />
    <StaticQuery
      query={graphql`
        query ContentfulProjects {
          __typename
          allContentfulWork {
            edges {
              node {
                title
                thumbnail {
                  file {
                    url
                  }
                }
                subtitle
                tags
                gridCol
                gridRow
                path
              }
            }
            totalCount
          }
        }
      `}
      render={({ allContentfulWork }) => (
        <Fade bottom>
          <ProjectCardContainer minWidth="300px">
            {allContentfulWork.edges.map((project) => (
              <Project {...project.node} key={project.id} />
            ))}
          </ProjectCardContainer>
        </Fade>
      )}
    />
  </Section.Container>
);

export default Projects;
