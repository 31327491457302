import styled from 'styled-components';
import { Card as CardRebass } from 'rebass';

export const ProjectCardContainer = styled.div`
  margin: 4rem;
  margin-top: 2rem;

  display: grid;
  grid-gap: 30px;

  grid-template-columns: repeat(
    auto-fill,
    minmax(${props => props.minWidth}, 1fr)
  );
  justify-items: left;

  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin: 1rem;
  }
`;

export const ProjectCard = styled(CardRebass).attrs({
  bg: '#F6F6F6',
  borderRadius: 10,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#FAFCFF',
})`
  position: relative;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 1), 10px 10px 20px rgba(102, 119, 170, 0.1);
  transition: all 0.25s;
  top: 0;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  background-image: url(${props => props.projektBackground});
  width: calc(100% - ${props => props.cardWith}); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 1200px) {
    grid-row: ${props => props.projectGridRow};
    grid-column: ${props => props.projectGridCol};
  }

  &:hover {
    top: -10px;
    box-shadow: 0 12px 16px rgba(102, 119, 170, 0.2);
  }

  ${props => props.mediaQuerySmall} {
    width: calc(100% - (${props => props.cardWith} / 2));
  }
  
`;

export default ProjectCard;
