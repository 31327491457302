import React, { Fragment } from 'react';
import Headroom from 'react-headroom';
import { Text, Flex, Image } from 'rebass';
import styled from 'styled-components';
import { SectionLinks } from 'react-scroll-section';
import Fade from 'react-reveal/Fade';
import RouteLink from './RouteLink';
import Logo from './Logo/JohnTheHuman.png';

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${props => props.theme.colors.tertiaryDark};   
    box-shadow: 0 20px 40px 0 rgba(102, 119, 170, 0.15);
    border-bottom: 1px solid ${props => props.theme.colors.tertiaryLight};
  }

  background: 'none';
  position: absolute;
  width: 100%;
`;

const formatLinks = allLinks =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

const Header = () => (
  <HeaderContainer>
    <Fade top>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <SectionLinks>
          {({ allLinks }) => {
            const { home, links } = formatLinks(allLinks);

            const homeLink = home && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Image
                  src={Logo}
                  onClick={home.onClick}
                  alt="Portfolio Logo"
                  style={{
                    cursor: 'pointer',
                    width: "50px",
                    height: "50px"
                  }}
                />
                <Text
                  onClick={home.onClick}
                  fontSize={[2, 3]}
                  style={{
                    marginLeft: '10px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    cursor: 'pointer',
                  }}
                >
                  John The Human
                </Text>
              </div>
            );
            const navLinks = links.map(({ name, value }) => {
              return (
                <RouteLink
                  key={name}
                  onClick={value.onClick}
                  selected={value.selected}
                  name={name}
                />
              );
            });

            return (
              <Fragment>
                {homeLink}
                <Flex mr={[0, 3, 5]}>{navLinks}</Flex>
              </Fragment>
            );
          }}
        </SectionLinks>
      </Flex>
    </Fade>
  </HeaderContainer>
);

export default Header;
