import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import MyButton from '../components/MyButton';

const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Contact = () => {
  return (
    <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
      <ButtonContainer width={[1]} px={[1, 2, 4]}>
        <Fade bottom>
          <a href="mailto:office@johnthehuman.com?subject=Greetings">
            <MyButton label="send greetings ✉️" />
          </a>
        </Fade>
      </ButtonContainer>
    </Flex>
  );
};

export default Contact;
