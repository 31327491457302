import React from 'react';
import { Heading, Text } from 'rebass';
import TextLoop from "react-text-loop";

const centerHorizontally = {
  fontStyle: 'italic',
  marginTop: '-20%',
  textAlign: 'right',

  /* transform: `rotate(-10deg)` */
};

const str2 = " ready to accelerate your business ";

const TitleComponent = () => (  

  <div
    style={
      centerHorizontally
    }
  >
    <Heading
      as="h1"
      color="totalDark"
      fontSize={[5, 6, 8]}
      mb={[2, 3, 4]}
    >
      {`Hi, I'm Johnny!`}
    </Heading>

    <Heading
      as="h2"
      color="totalDark"
      fontSize={[4, 5, 6]}
      mb={[3, 5]}
    >
      <Text
        style={{
          zIndex:2,
        }}
      >

        <TextLoop interval={3000} style={{ height: "50" }}>
          <span>A UX-Designer </span>
          <span>A creative mind </span>
          <span>A design thinker </span>
          <span>A code hobbyist </span>
        </TextLoop>

        {str2}

      </Text>
    </Heading>
  </div>
              );

export default TitleComponent